<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link href="/" class="brand-logo">
        <ff-logo class="header-logo" />

        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Create password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
          v-if="showContentBox"
        >
          <b-navbar-nav class="nav ml-auto btn-locale">
            <locale />
          </b-navbar-nav>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="20"
              class="mr-50"
            />
            {{ $t('Modules.User.Label.Reset password') }}
          </b-card-title>
          <b-card-text class="mb-2 font-style-italic">
            * {{ $t('Modules.User.Label.Password must have at least 8 characters and contain letter, number') }}
          </b-card-text>
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group
                :label="$t('Modules.User.Label.New password')"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Modules.User.Label.Password')"
                  vid="password"
                  rules="required|min:8|regex:^(?=.*?[a-zA-Z])(?=.*?[0-9]).*$"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                :label="$t('Modules.User.Label.Confirm password')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Modules.User.Label.Confirm password field')"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                {{ $t('Save') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('Back to login') }}
            </b-link>
          </p>
        </b-col>
        <message-box v-else :title="messageBox.title" :message="messageBox.body" :status="messageBox.status"></message-box>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import FfLogo from "@core/layouts/components/ff-logo.vue";
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg, BNavbarNav
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import AuthRepository from "@/modules/auth/repository/authRepository";
import MessageBox from '@core/components/custom/messageBox.vue'
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";

export default {
  components: {
    FfLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BNavbarNav,
    Locale,
    MessageBox
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      messageBox: {
        title: '',
        body: '',
        status: true
      },
      showContentBox: true
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const vm = this;
          AuthRepository.resetPassword({resetPasswordCode: router.currentRoute.params.resetPasswordCode, password: vm.password})
          .then(response => {
            vm.messageBox.title = vm.$i18n.t('Successful') + '!'
            vm.messageBox.body = response.data.data.message
            vm.showContentBox = false
          })
        }
      })
    },
  },
  created() {

  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
